<template>
  <div class="thank-you">
    <div class="wrapper">
      <h1>Thank you!</h1>
      <p>Your payment is successful.</p>
      <Button :message="'Go shop'"></Button>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button/CustomButton.vue";

export default {
  name: "ThankYou",
  components: {
    Button,
  },
};
</script>
